<template>
  <van-tabbar route @change="setActive">
    <van-tabbar-item
      v-for="(item, index) in tabbarList"
      :key="index"
      replace
      :to="item.pagePath"
    >
      <span
        :class="
          tabbarActive == index ? 'tabbar-color tabbar-active' : 'tabbar-color'
        "
        >{{ item.text }}</span
      >
      <template #icon>
        <img
          :src="tabbarActive == index ? item.selectedIconPath : item.iconPath"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    name: 'app-Tabbar',
    data() {
      return {
        // active: 0,
      };
    },
    computed: {
      ...mapState({
        tabbarList: (state) => state.app.tabbarList,
        tabbarActive: (state) => state.app.tabbarActive,
      }),
    },
    watch: {
      $route: {
        handler: function (N) {
          if (N) {
            this.tabbarList.forEach((el, i) => {
              if (N.path.includes(el.pageType)) {
                this.SET_TABBARACTIVE(i);
              }
            });
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      ...mapMutations('app', ['SET_TABBARACTIVE']),
      setActive(value) {
        this.SET_TABBARACTIVE(value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tabbar-active {
    color: #206cfe;
  }
</style>
