<template>
	<div class="mapSearch">
		<amap-search
			v-if="hackReset"
			:searchCount="2"
			:autoConfirm="true"
			:useClick="true"
			:width="320"
			:height="400"
			@pickedLocation="handlePickedLocation">
		</amap-search>
		
	</div>
</template>

<script>
	import AmapSearch from 'vue-amap-search'
	import 'vue-amap-search/dist/lib/main.css'
	export default{
		name: 'MapPointPick',
		data(){
			return{
				hackReset:true
			}
		},
		components:{
			'amap-search': AmapSearch
		},
		methods:{
			handlePickedLocation(mapInfo){
				this.$emit("bindMapInfoToForm",mapInfo)
			},
			resetMap(){
				this.hackReset = false
				this.$nextTick(()=>{
					this.hackReset = true
				})
			}
		}
	}
</script>

<style lang="scss">
	.mapSearch{
		.amap-search__autocomplate{
			padding-left:60px;
			width: 300px;
			height: 40px;
			line-height: 40px;
			left: 10px;
		}
		.amap-search__autocomplate a.amap-search__search-btn{
			position: absolute;
			left: 0;
			width: 60px;
			height: 40px;
			line-height: 40px;
			cursor: pointer;
			text-align: center;
		}
		.amap-search__confirm-btn{
			display: none !important;
		}
	}
	.amap-sug-result{
		z-index: 99999;
		left: 10%!important;
		top: 27%!important;
		max-width: 300px;
	}
	.amap-sug-result .auto-item{
		overflow: hidden;
	}
</style>
