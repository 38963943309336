<template>
  <div class="loading-bg" v-if="loadingShow">
    <img src="../../assets/common/loading.gif" alt="" />
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    data() {
      return {
        loadingShow: false,
      };
    },
    methods: {
      showLoading() {
        this.loadingShow = true;
      },
      hideLoading() {
        this.loadingShow = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loading-bg {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(223, 235, 237, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    img {
      height: 50px;
      width: 50px;
    }
  }
</style>
