import AppTabbar from './tabbar/index.vue';
import QuillEditors from './quillEditors/index.vue';
import MapPointPick from './map/index.vue';
const components = [AppTabbar, QuillEditors, MapPointPick];

const install = (Vue) => {
  components.map((component) => {
    Vue.component(component.name, component);
  });

  if (typeof Window !== 'undefined' && window.Vue) {
    install(Window.Vue);
  }
};

export default {
  install,
};
