import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/layout/index'),
    redirect: '/positionMgt/index',
    children: [
      {
        path: 'positionMgt/index',
        component: () => import('@/views/positionMgt/index'),
        name: 'positionMgt',
      },

      {
        path: 'positionMgt/positionAddOrEdit',
        component: () => import('@/views/positionMgt/addOrEdit'),
        name: 'positionAddOrEdit',
      },
      {
        path: 'positionMgt/positionDetail',
        component: () => import('@/views/positionMgt/positionDetail'),
        name: 'positionDetail',
      },
      {
        path: 'auditing',
        component: () => import('@/views/others/auditing'),
        name: 'auditing',
      },
      {
        path: 'noLegalize',
        component: () => import('@/views/others/noLegalize'),
        name: 'noLegalize',
      },
      {
        path: 'noPassAudit',
        component: () => import('@/views/others/noPassAudit'),
        name: 'noPassAudit',
      },
      {
        path: 'passAudit',
        component: () => import('@/views/others/passAudit'),
        name: 'passAudit',
      },
      {
        path: 'special/index',
        component: () => import('@/views/others/awaiting'),
        name: 'special',
      },
      {
        path: 'deliveryRecord/index',
        component: () => import('@/views/deliveryRecord/index'),
        name: 'deliveryRecord',
      },
      {
        path: 'deliveryRecord/preview',
        component: () => import('@/views/deliveryRecord/preview'),
        name: 'preview',
      },
      {
        path: 'deliveryRecord/previewOnline',
        component: () => import('@/views/deliveryRecord/previewOnline'),
        name: 'previewOnline',
      },
      {
        path: 'release/index',
        component: () => import('@/views/release/index'),
        name: 'release',
      },
      {
        path: 'release/releaseAddOrEdit',
        component: () => import('@/views/release/addOrEdit'),
        name: 'releaseAddOrEdit',
      },
      {
        path: 'myHome/index',
        component: () => import('@/views/myHome/index'),
        name: 'myHome',
      },
      {
        path: 'myHome/setting',
        component: () => import('@/views/myHome/setting.vue'),
        name: 'setting',
      },
      {
        path: 'myHome/editTel',
        component: () => import('@/views/myHome/editTel.vue'),
        name: 'editTel',
      },
      {
        path: 'myHome/editPwd',
        component: () => import('@/views/myHome/editPwd.vue'),
        name: 'editPwd',
      },
      {
        path: 'myHome/editInfo',
        component: () => import('@/views/myHome/editInfo.vue'),
        name: 'editInfo',
      },
      {
        path: 'myHome/baseInfoEdit',
        component: () => import('@/views/myHome/baseInfoEdit.vue'),
        name: 'baseInfoEdit',
      },
      {
        path: 'myHome/feedbackCycle',
        component: () => import('@/views/myHome/feedbackCycle'),
        name: 'feedbackCycle',
      },
      {
        path: 'myHome/companyInfo',
        component: () => import('@/views/myHome/companyInfo'),
        name: 'companyInfo',
      },
      {
        path: 'myHome/companyAddOrEdit',
        component: () => import('@/views/myHome/companyAddOrEdit'),
        name: 'companyAddOrEdit',
      },
      {
        path: 'companyBinding',
        component: () => import('@/views/others/companyBinding'),
        name: 'companyBinding',
      },
      {
        path: 'myHome/deptAdd',
        component: () => import('@/views/myHome/deptAdd'),
        name: 'deptAdd',
      },
      {
        path: 'myHome/resumeLibrary',
        component: () => import('@/views/others/awaiting.vue'),
        name: 'resumeLibrary',
      },
      {
        path: 'myHome/dataView',
        component: () => import('@/views/myHome/dataView.vue'),
        name: 'dataView',
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/wxlogin',
    name: 'wxlogin',
    component: () => import('@/views/login/wxLogin'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/others/awaiting.vue'),
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
export default router;
