/* eslint-disable no-unused-vars */
import Vue from 'vue';
import ownLoading from './index.vue';

const OwnLoading = {
  install(Vue, options) {
    const loadingConstructor = Vue.extend(ownLoading);
    let vueLoading = null
    const div = document.createElement('div');
    document.body.appendChild(div);
    const vm = new loadingConstructor({
      propsData: options,
    }).$mount(div);
    vueLoading = vm;
    Vue.prototype.$ownLoading = vueLoading
    Vue.prototype.$ownLoading.show = function() {
      vueLoading.showLoading()
    }
    Vue.prototype.$ownLoading.hide = function() {
      if (vueLoading) {
        vueLoading.hideLoading()
      }
    }
    Vue.$loading = Vue.prototype.$ownLoading
  },
};

export default OwnLoading;
