const state = {
  tabbarList: [
    {
      iconPath: require('../../assets/common/position.png'),
      pagePath: '/positionMgt/index',
      pageType: 'positionMgt',
      selected: true,
      selectedIconPath: require('../../assets/common/position_active.png'),
      text: '职位',
      type: '0',
    },
    {
      iconPath: require('../../assets/common/special.png'),
      isSpecial: true,
      pagePath: '/special/index',
      pageType: 'special',
      selected: false,
      selectedIconPath: require('../../assets/common/special_active.png'),
      text: '专场',
      type: '0',
    },
    {
      iconPath: require('../../assets/common/delivery_record.png'),
      pagePath: '/deliveryRecord/index',
      pageType: 'deliveryRecord',
      selected: false,
      selectedIconPath: require('../../assets/common/delivery_record_active.png'),
      text: '投递记录',
      type: '0',
    },
    {
      iconPath: require('../../assets/common/release.png'),
      pagePath: '/release/index',
      pageType: 'release',
      selected: false,
      selectedIconPath: require('../../assets/common/release_active.png'),
      text: '公众号发布',
      type: '0',
    },
    {
      iconPath: require('../../assets/common/my_home.png'),
      pagePath: '/myHome/index',
      pageType: 'myHome',
      selected: false,
      selectedIconPath: require('../../assets/common/my_home_active.png'),
      text: '我的',
      type: '0',
    },
  ],
  tabbarActive: 0
};

const mutations = {
  SET_TABBARlIST: (state, tabbarList) => {
    state.tabbarList = tabbarList;
  },
  SET_TABBARACTIVE: (state, tabbarActive) => {
    state.tabbarActive = tabbarActive;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
