import Vue from 'vue';
import qs from 'qs';
import { Toast } from 'vant';
import router from './../router/index';
import { baseUrl } from './url';
import store from './../store/index';

/**
 * 返回header
 * @returns {object}
 */

const getHeaders = function (type = 'post') {
  const token = localStorage.getItem('accessToken');
  // 图片上传请求
  if (type === 'upload') {
    return {
      headers: {
        // 'Content-Type': 'multipart/form-data;'
      },
    };
  }
  return {
    headers: {
      'Content-Type':
        type === 'post'
          ? 'application/json'
          : 'application/x-www-form-urlencoded;charset=utf-8',
      token: token || null,
    },
  };
};
/**
 * get 请求
 * @param url
 * @param params
 * @param isHandleError
 * @param httpCustomerOption 使用者传递过来的参数, 用于以后的扩展用户自定义的行为
 * {
 *    isHandleResult: boolean    //是否需要处理错误结果   true 需要/false 不需要
 *    isShowLoading: boolean     //是否需要显示loading动画
 *    customHead: object        // 自定义的请求头
 *    timeout: int              //自定义接口超时的时间
 * }
 * @returns {Promise}
 **/
const get = function (
  url,
  params = {},
  httpCustomerOption = { isHandleResult: true, isShowLoading: true }
) {
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isHandleResult')
  ) {
    httpCustomerOption.isHandleResult = true;
  }
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isShowLoading')
  ) {
    httpCustomerOption.isShowLoading = true;
  }
  const method = 'GET';
  const fetchUrl = Object.keys(params).length
    ? url + '?' + qs.stringify(params)
    : url; // 将参数转化到url上
  const fetchParams = Object.assign({}, { method }, getHeaders('get'));
  return handleFetchData(fetchUrl, fetchParams, httpCustomerOption);
};

/**
 * download 请求
 * @param url
 * @param params
 * @param isHandleError
 * @param httpCustomerOption 使用者传递过来的参数, 用于以后的扩展用户自定义的行为
 * {
 *    isHandleResult: boolean    //是否需要处理错误结果   true 需要/false 不需要
 *    isShowLoading: boolean     //是否需要显示loading动画
 *    customHead: object        // 自定义的请求头
 *    timeout: int              //自定义接口超时的时间
 * }
 * @returns {Promise}
 **/
const download = function (
  url,
  params = {},
  httpCustomerOption = {
    isHandleResult: true,
    isShowLoading: true,
    responseType: 'blob',
  }
) {
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isHandleResult')
  ) {
    httpCustomerOption.isHandleResult = true;
  }
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isShowLoading')
  ) {
    httpCustomerOption.isShowLoading = true;
  }
  const method = 'GET';
  const fetchUrl = Object.keys(params).length
    ? url + '?' + qs.stringify(params)
    : url; // 将参数转化到url上
  const fetchParams = Object.assign({}, { method }, getHeaders('get', true));
  return handleFetchData(fetchUrl, fetchParams, httpCustomerOption);
};

/**
 * put 请求
 * @param url
 * @param params
 * @param isHandleError
 * @param httpCustomerOption 使用者传递过来的参数, 用于以后的扩展用户自定义的行为
 * @returns {Promise}
 **/
const put = function (
  url,
  params = {},
  httpCustomerOption = { isHandleResult: true, isShowLoading: true }
) {
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isHandleResult')
  ) {
    httpCustomerOption.isHandleResult = true;
  }
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isShowLoading')
  ) {
    httpCustomerOption.isShowLoading = true;
  }
  const method = 'PUT';
  const body = JSON.stringify(params); // 将参数转化成JSON字符串
  const fetchParams = Object.assign({}, { method, body }, getHeaders());
  return handleFetchData(url, fetchParams, httpCustomerOption);
};

/**
 * post 请求
 * @param url
 * @param params
 * @param isHandleError
 * @param httpCustomerOption 使用者传递过来的参数, 用于以后的扩展用户自定义的行为
 * @returns {Promise}
 **/
const post = function (
  url,
  params = {},
  httpCustomerOption = { isHandleResult: true, isShowLoading: true }
) {
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isHandleResult')
  ) {
    httpCustomerOption.isHandleResult = true;
  }
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isShowLoading')
  ) {
    httpCustomerOption.isShowLoading = true;
  }
  const method = 'POST';
  const body = JSON.stringify(params); // 将参数转化成JSON字符串
  const fetchParams = Object.assign({}, { method, body }, getHeaders());
  return handleFetchData(url, fetchParams, httpCustomerOption);
};

/**
 * postUpload 图片上传请求
 * @param url
 * @param params
 * @param isHandleError
 * @param httpCustomerOption 使用者传递过来的参数, 用于以后的扩展用户自定义的行为
 * @returns {Promise}
 **/
const postUpload = function (
  url,
  params = {},
  httpCustomerOption = { isHandleResult: true, isShowLoading: true }
) {
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isHandleResult')
  ) {
    httpCustomerOption.isHandleResult = true;
  }
  if (
    !Object.prototype.hasOwnProperty.call(httpCustomerOption, 'isShowLoading')
  ) {
    httpCustomerOption.isShowLoading = true;
  }
  const method = 'POST';
  const fetchParams = Object.assign(
    {},
    { method },
    { body: params },
    getHeaders('upload')
  );
  return handleFetchData(url, fetchParams, httpCustomerOption);
};

/**
 * 发送fetch请求
 * @param fetchUrl
 * @param fetchParams
 * @returns {Promise}
 */
const handleFetchData = function (fetchUrl, fetchParams, httpCustomerOption) {
  // 1. 处理的第一步
  const { isShowLoading } = httpCustomerOption;
  if (isShowLoading) {
    Vue.$loading.show();
  }
  httpCustomerOption.isFetched = false;
  httpCustomerOption.isAbort = false;
  // 处理自定义的请求头
  if (Object.prototype.hasOwnProperty.call(httpCustomerOption, 'customHead')) {
    const { customHead } = httpCustomerOption;
    fetchParams.headers = Object.assign({}, fetchParams.headers, customHead);
  }
  if (httpCustomerOption.responseType) {
    fetchParams.responseType = httpCustomerOption.responseType;
  }
  // 2. 对fetch请求再进行一次Promise的封装
  const fetchPromise = new Promise((resolve, reject) => {
    fetch(baseUrl + fetchUrl, fetchParams)
      .then((response) => {
        // 3. 放弃迟到的响应
        if (httpCustomerOption.isAbort) {
          // 3. 请求超时后，放弃迟到的响应
          return;
        }
        if (isShowLoading) {
          Vue.$loading.hide();
        }
        httpCustomerOption.isFetched = true;
        if (httpCustomerOption.responseType === 'blob') {
          response.blob().then((blob) => {
            resolve(blob);
          });
        } else {
          response
            .json()
            .then((jsonBody) => {
              if (response.ok) {
                // 4. 统一处理返回结果
                if (jsonBody.code === 401 || jsonBody.code === 98030) {
                  // token失效,重新登录
                  store.commit('user/SET_ISLOGIN', false);
                  turnToLogin();
                } else if (jsonBody.status) {
                  // 业务逻辑报错, 不属于接口报错的范畴
                  reject(handleFailedResult(jsonBody, httpCustomerOption));
                } else {
                  resolve(handleResult(jsonBody, httpCustomerOption));
                }
              } else {
                // 5. 接口状态判断
                // http status header <200 || >299
                let msg = '当前服务繁忙，请稍后再试';
                if (response.status === 404) {
                  msg = '您访问的内容走丢了…';
                }
                Toast(msg, 2);
                reject(
                  handleFailedResult(
                    {
                      fetchStatus: 'error',
                      netStatus: response.status,
                      error: msg,
                    },
                    httpCustomerOption
                  )
                );
              }
            })
            .catch((e) => {
              const errMsg = e.name + ' ' + e.message;
              reject(
                handleFailedResult(
                  {
                    fetchStatus: 'error',
                    error: errMsg,
                    netStatus: response.status,
                  },
                  httpCustomerOption
                )
              );
            });
        }
      })
      .catch((e) => {
        const errMsg = e.name + ' ' + e.message;
        // console.error('ERR:', fetchUrl, errMsg)
        if (httpCustomerOption.isAbort) {
          // 请求超时后，放弃迟到的响应
          return;
        }
        if (isShowLoading) {
          Vue.$loading.hide();
        }
        httpCustomerOption.isFetched = true;
        httpCustomerOption.isHandleResult &&
          Toast('网络开小差了，稍后再试吧', 2);
        reject(
          handleFailedResult(
            { fetchStatus: 'error', error: errMsg },
            httpCustomerOption
          )
        );
      });
  });
  return Promise.race([fetchPromise, fetchTimeout(httpCustomerOption)]);
};

/**
 * 统一处理后台返回的结果, 包括业务逻辑报错的结果
 * @param result
 * ps: 通过 this.isHandleError 来判断是否需要有fetch方法来统一处理错误信息
 */
const handleResult = (result, httpCustomerOption) => {
  if (result.status && httpCustomerOption.isHandleResult === true) {
    const errMsg = result.msg || result.message || '服务器开小差了，稍后再试吧';
    const errStr = `${errMsg}（${result.status}）`;
    Vue.$loading.hide();
    Toast(errStr, 2);
  }
  return result;
};
/**
 * 统一处fetch的异常, 不包括业务逻辑报错
 * @param result
 * ps: 通过 this.isHandleError 来判断是否需要有fetch方法来统一处理错误信息
 */
const handleFailedResult = function (result, httpCustomerOption) {
  if (result.status && httpCustomerOption.isHandleResult === true) {
    const errMsg = result.msg || result.message || '服务器开小差了，稍后再试吧';
    const errStr = `${errMsg}（${result.status}）`;
    Vue.$loading.hide();
    Toast(errStr, 2);
  }
  const errorMsg =
    'Uncaught PromiseError: ' +
    (result.netStatus || '') +
    ' ' +
    (result.error || result.msg || result.message || '');
  return errorMsg;
};
/**
 * 控制Fetch请求是否超时
 * @returns {Promise}
 */
const fetchTimeout = function (httpCustomerOption) {
  const { isShowLoading } = httpCustomerOption;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!httpCustomerOption.isFetched) {
        // 还未收到响应，则开始超时逻辑，并标记fetch需要放弃
        httpCustomerOption.isAbort = true;
        // console.error('ERR: 请求超时')
        if (isShowLoading) {
          Vue.$loading.hide();
        }
        Toast('网络开小差了，稍后再试吧', 2);
        reject({ fetchStatus: 'timeout' });
      }
    }, httpCustomerOption.timeout || 30000);
  });
};

/**
 * 返回登录页
 * @returns {Promise}
 */
const turnToLogin = function () {
  localStorage.setItem('userInfo', '');
  localStorage.setItem('accessToken', '');
  router.push('/login');
};

export { download, get, post, postUpload, put };
