const state = {
  token: '',
  isLogin: false,
};
const getters = {
  GET_ISLOGIN: (state) => {
    return state.isLogin;
  },
};
const mutations = {
  GET_ISLOGIN: (state) => {
    return state.isLogin;
  },
  SET_ISLOGIN: (state, isLogin) => {
    state.isLogin = isLogin;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
