import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import install from './components/index.js';
import OwnLoading from './components/loading/index.js';
import vant from 'vant';
import { Toast } from 'vant';
import { getUrlAllParams } from './utils/tool'
import './common/reset.css';
import 'vant/lib/index.css';

import { download,get, post, postUpload, put } from './utils/http.js';
Vue.prototype.$http = {
  download,
  get,
  post,
  postUpload,
  put,
};
Vue.prototype.OwnLoading = OwnLoading;
Vue.use(OwnLoading);
Vue.use(vant);

Toast.setDefaultOptions({ duration: 3000 });
const plugins = [install];
plugins.forEach((p) => Vue.use(p));

Vue.config.productionTip = false;

console.log(window.location.search)
console.log(getUrlAllParams())

var init = async () => {
  let params = getUrlAllParams()
  let isInMini = false; // 是否处于小程序内部
  if (params.token) {
    isInMini = true
    let historyToken = localStorage.getItem('accessToken')
    if (!historyToken || historyToken !== params.token) {
      localStorage.setItem('accessToken', params.token);
      let back = await get('/selfhelpOfficialAccounts/relation');
      if (back.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(back.data));
      }
    }
  }
  new Vue({
    router,
    store,
    render: (h) => h(App),
    data: {
      isInMini
    }
  }).$mount('#app');
}
init()


