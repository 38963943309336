<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style lang="scss" scoped>
  #app {
    height: 100vh;
    width: 100vw;
  }
</style>
<style>
  /* .amap-search__autocomplate{
		display: none!important;
	} */
  .van-nav-bar__content {
    height: 44px !important;
    line-height: 44px;
  }
  .van-nav-bar .van-nav-bar__title {
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .van-dropdown-menu__title .van-ellipsis {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .van-checkbox {
    margin-top: 3px;
  }
  .van-radio {
    margin-top: 3px;
  }
  .van-dialog__confirm .van-button__text {
    color: #206cfe;
  }
  input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */

    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .filed-box .van-cell {
    line-height: 27px;
  }
  .filed-box > .van-cell {
    padding: 23px 0 23px 16px;
  }
  .btn-box .van-button {
    height: 47px;
  }
  .van-field__label {
    max-width: 50%;
    width: auto !important;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .van-dialog .van-dialog__footer {
    display: flex;
    flex-direction: row-reverse;
  }
  .van-checkbox__icon {
    font-size: 17px !important;
  }
  .van-radio__icon {
    font-size: 17px !important;
  }
  .van-checkbox__icon .van-icon {
    height: 15px !important;
    width: 15px !important;
    margin-top: 2px !important;
  }
  .van-radio__icon .van-icon {
    height: 15px !important;
    width: 15px !important;
    margin-top: 2px !important;
  }
</style>
