/* 获取全部url参数,并转换成json对象 */
export const getUrlAllParams = function (urls) {
  var url = urls || window.location.href;
  var _pa = url.substring(url.indexOf('?') + 1);

  var _arrS = _pa.split('&');
  var _rs = {};
  for (var i = 0, _len = _arrS.length; i < _len; i++) {
    var pos = _arrS[i].indexOf('=');
    if (pos === -1) {
      continue;
    }
    var name = _arrS[i].substring(0, pos);
    var value = window.decodeURIComponent(_arrS[i].substring(pos + 1));
    var index = value.indexOf('#/');
    if (index > 0) {
      value = value.substr(0, index);
    }
    _rs[name] = value;
  }
  return _rs;
};

/**
 *
 * @desc   判断是否为手机号
 * @param  {String|Number} str
 * @return {Boolean}
 */
export const isPhoneNum = function (str) {
  return /^(\+?0?86-?)?1[3456789]\d{9}$/.test(str);
};

//设置cookie
export const setCookie = function (c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toGMTString());
};

//取回cookie
export const getCookie = function (c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};
